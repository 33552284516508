<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/table.css";
</style>
<style scoped>
  /deep/ .el-button--mini {
    padding: 7px 6px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import ChangeScore from "@/components/change-score.vue"
  import ScheduleTable from './schedule-table';
  import {
    getCjkStatic,
    refreshCjkStatic
  } from "@/api/statistics/statistics.js"

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      ScheduleTable,
      ChangeScore
    },
    data() {
      return {
        title: "成绩管理",
        items: [{
            text: "用户首页",
            href: "/admin"

          },
          {
            text: "成绩管理",
            active: true
          }
        ],
        cjkid: "",
        scope: "numOfMonth",
        isIndexChange: true,
        dayNum: {},
        yearNum: {},
        monthNum: {},
        formDate: {},
        ksmc: "",
        cjfb: {}, //成绩发布
        cjjl: {}, //成绩记录
        cjfh: {}, //成绩复核
        statisticaAll: {}, //总记录
        dataBox: {},
        componentKey: 1,
        score: {},
        /*   statData: [{
               title: "注册用户",
               icon: " icon-book--line",
               value: "34565",
               text: "今日新注册用户 575 人"
             },
             {
               title: "报考数据",
               icon: "icon-stLine-doc-edit-l",
               value: "4564",
               text: "今年报考246589人次，较上年度 112%"
             },
             {
               title: "成绩数据",
               icon: "icon-newspaper-o",
               value: "23456",
               text: "今日新新增4578554条，较上年度 98%"
             }
           ], */
        // 柱状图
        series: [{
            name: new Date().getFullYear(),
            type: "column",
            data: []
          },
          // {
          //   name: "2019",
          //   type: "line",
          //   data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
          // }
        ],
        chartOptions: {
          chart: {
            height: 280,
            type: "line",
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: [0, 3],
            curve: "smooth"
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%"
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: [],
          // labels: [
          //   "3:00",
          //   "5:00",
          //   "7:00",
          //   "9:00",
          //   "11:00",
          //   "13:00",
          //   "15:00",
          //   "17:00",
          //   "19:00",
          //   "21:00",
          //   "23:00",
          //   "24:00"
          // ],

        },
        // 环形图
        chartOptionsCjfb: {
          chart: {
            height: 230,
            type: "donut"
          },
          labels: ["优秀", "良好", "中等", "合格", "不合格"],
          plotOptions: {
            pie: {
              donut: {
                size: "75%"
              }
            }
          },
          dataLabels: {
            enabled: false

          },
          legend: {
            show: false
          },
          colors: ["#5087EC", "#68BBC4", "#58A55C", "#F2BD42", "#EE752F"]
        },
      };
    },
    methods: {

      // 切换统计类型
      chooseTrend(type) {
        this.scope = type
        this.series[0].data = this.dataBox[type];
        this.componentKey += 1; //用来流畅切换
      },
      // 详情统计
      getScoreStatistics(params) {
        let newParam = params ? params : ''
        getCjkStatic(newParam).then(res => {
          console.log(res)
          if (res.status) {
            if (res.data) {
              this.cjfb = res.data.countAndWillOpen
              this.cjjl = res.data.countAndNew
              this.cjfh = res.data.reviewCountAndNew //成绩复核的
              this.statisticaAll = res.data.statisticaAll

              if (res.data.statisticaBycjkid) {
                this.statisticaAll = res.data.statisticaBycjkid //某个成绩库下
                this.statisticaAll.count = res.data.statisticaBycjkid.cjjl
                this.ksmc = res.data.statisticaBycjkid.ksmc
              } else {
                if (newParam) {
                  this.$message({
                    type: 'warning',
                    message: '该库成绩未导入,将继续显示全部数据！'
                  });
                }
              }
              this.dayNum = res.data.numOfDay ? res.data.numOfDay : {}
              this.monthNum = res.data.numOfMonth ? res.data.numOfMonth : {}
              this.yearNum = res.data.numOfYear ? res.data.numOfYear : {}

              // 类型匹配
              let box = ['numOfDay', 'numOfMonth', 'numOfYear'];
              let need = {}
              box.forEach(k => {
                let main = res.data[k]
                need[k] = []
                if (main) {
                  // for (let i = 2; i < 24; i = i + 2) {
                  for (let i = 0; i < 24; i++) {
                    need[k].push(main["query" + i])
                    this.chartOptions.labels.push(i + ":00")
                  }
                }

              })
              this.dataBox = need
              this.$nextTick(() => {
                this.chooseTrend("numOfMonth")
              })
            }
          }




        })
      },
      refreshData() { //刷新统计数据
        this.$confirm('更新将会占用大量系统资源，请勿在考试报名期间更新，是否确认更新（更新期间请耐心等待）？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refreshCjkStatic().then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据统计成功!'
              });
              this.getScoreStatistics()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消刷新'
          });
        });

      }
    },
    mounted() {
      this.cjkid = this.$route.query.sid ? this.$route.query.sid : ""
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ""
      this.getScoreStatistics(this.cjkid)

    },
    computed: {
      percent() {
        return function(key) {
          if (!this.statisticaAll.cjjl) {
            return 0 + "%"
          } else {
            if (!this.statisticaAll[key]) {
              return 0 + "%"
            } else {
              return Number((this.statisticaAll[key] / this.statisticaAll.cjjl) * 100).toFixed(2) + "%"
            }
          }
        }
      },
      seriesCjfb() {
        let keyBox = ["yxsl", "lhsl", "zdsl", "hgsl", "bhgsl"]
        let arr = []
        keyBox.forEach((k) => {
          arr.push(this.statisticaAll[k] || 0)
        })
        return arr
      },
      topdd() {
        return [{
            title: "成绩库发布",
            icon: " icon-book--line",
            value: this.cjfb.count || 0,
            text: "即将开放查询" + (this.cjfb.willOpen || 0) + "项"
          },
          {
            title: "成绩记录",
            icon: "icon-stLine-doc-edit-l",
            value: this.cjjl.count || 0,
            text: "今年新增" + (this.cjjl.new || 0) + "人次" + (!this.cjjl.lastYear || this.cjjl
              .lastYear == 0 ? "" : "，较上年度" + ((this.cjjl.new / this.cjjl.lastYear).toFixed(2) * 100) +
              "%")
          },
          {
            title: "成绩复核",
            icon: "icon-newspaper-o",
            value: this.cjfh.count || 0,
            text: "新增申请" + (this.cjfh.new || 0) + "条"
          }
        ]
      }
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-md-4" v-for="(data, index) in topdd" :key="index">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
                    <h4 class="mb-0">{{data.value}}</h4>
                  </div>
                  <div class="text-info">
                    <i :class="`${data.icon} font-size-24 iconfont`"></i>
                  </div>
                </div>
              </div>

              <div class="card-body border-top py-3">
                <div class="text-truncate">
                  <span class="text-muted ">{{data.text}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfDay'?'active':'']"
                  @click="chooseTrend('numOfDay')">今天</button>
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfMonth'?'active':'']"
                  @click="chooseTrend('numOfMonth')">本月</button>
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfWeek'?'active':'']"
                  @click="chooseTrend('numOfYear')">本年</button>
              </div>
            </div>
            <h4 class="card-title mb-4">查询趋势 <el-button type="primary" plain size="mini" class="h30 ml-2"
                @click="refreshData">刷新统计数据
              </el-button>
            </h4>

            <div>
              <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
              <apexchart class="apex-charts" height="280" dir="ltr" :series="series" :key="componentKey"
                :options="chartOptions">
              </apexchart>
            </div>
          </div>

          <div class="card-body border-top text-center">
            <div class="row">
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{this.cjfh.new || 0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">新申请复核

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{dayNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">今日查询

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{monthNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">本月查询

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{yearNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">今年查询

                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">成绩分布<span class="blue-font ml-2 d-inline-block"
                v-b-modal.showmodal>切换查看单项成绩</span>
              <span><a href=""><i class="iconfont icon-more head-more" id="more"></i></a>
                <b-tooltip target="more" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="mb-2" v-if="ksmc">{{ksmc}}</div>
            <div id="donut-chart" class="apex-charts"></div>
            <apexchart class="apex-charts" height="230" dir="ltr" :series="seriesCjfb" :options="chartOptionsCjfb">
            </apexchart>
            <div class="row">
              <div class="w-20">
                <div class="text-center mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="mdi mdi-circle text-success  font-size-10 mr-1"></i> 优秀

                  </p>
                  <h5 class="font-size-12">{{percent("yxsl")}}</h5>
                </div>
              </div>
              <div class="w-20">
                <div class="text-center mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="mdi mdi-circle text-info   font-size-10 mr-1"></i> 良好
                  </p>
                  <h5 class="font-size-12">{{percent("lhsl")}}</h5>
                </div>
              </div>
              <div class="w-20">
                <div class="text-center mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> 中等
                  </p>
                  <h5 class="font-size-12">{{percent("zdsl")}}</h5>
                </div>
              </div>
              <div class="w-20">
                <div class="text-center mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> 合格
                  </p>
                  <h5 class="font-size-12">{{percent("hgsl")}}</h5>
                </div>
              </div>
              <div class="w-20">
                <div class="text-center mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="mdi mdi-circle text-danger font-size-10 mr-1"></i> 不合格
                  </p>
                  <h5 class="font-size-12">{{percent("bhgsl")}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="min-height: 248px;">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash"><span class="mr-2">详细数据分布</span>
              <span><a href=""><i class="iconfont icon-more head-more" id="more3"></i></a>
                <b-tooltip target="more3" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="table-responsive border ">
              <table class="table table-has-bg light-table table-hover font-size-12 ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%;">优秀 </th>
                    <th style="width:5%"> 良好</th>
                    <th style="width:5%"> 中等</th>
                    <th style="width:5%"> 合格</th>
                    <th style="width:5%"> 不合格 </th>
                    <th style="width:5%"> 违纪 </th>
                    <th style="width:5%"> 缺考 </th>
                    <th style="width:5%"> 无效</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{statisticaAll.yxsl||0}}</td>
                    <td>{{statisticaAll.lhsl||0}}</td>
                    <td>{{statisticaAll.zdsl||0}}</td>
                    <td>{{statisticaAll.hgsl||0}}</td>
                    <td>{{statisticaAll.bhgsl||0}}</td>
                    <td>{{statisticaAll.wjsl||0}}</td>
                    <td>{{statisticaAll.qksl||0}}</td>
                    <td>{{statisticaAll.wxsl||0}}</td>
                  </tr>
                  <tr>
                    <td>{{percent("yxsl")}}</td>
                    <td>{{percent("lhsl")}}</td>
                    <td>{{percent("zdsl")}}</td>
                    <td>{{percent("hgsl")}}</td>
                    <td>{{percent("bhgsl")}}</td>
                    <td>{{percent("wjsl")}}</td>
                    <td>{{percent("qksl")}}</td>
                    <td>{{percent("wxsl")}}</td>
                  </tr>

                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <ScheduleTable />
      </div>

    </div>
    <!-- 切换成绩库 -->
    <ChangeScore :isIndexChange="isIndexChange" @getScoreStatistics="getScoreStatistics"></ChangeScore>
  </Layout>
</template>
