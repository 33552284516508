<script>
  import {
    getCjk,
  } from "@/api/score/scoreKu.js"
  export default {
    data() {
      return {
        tableList: [],
        pageData: {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          ksnf: new Date().getFullYear(),
        },
        fhfw: [
          "违纪",
          "缺考",
          "无效",
          "不及格",
          "全部",
        ],
      }
    },
    methods: {
      // 成绩库列表
      getList() {
        getCjk(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total


          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
    },
    mounted() {
      this.getList()
    },
    computed: {
      cjfhfw() {
        return function(a) {
          let box = this.fhfw;
          let str = ""
          for (let i = 0; i < a.length; i++) {
            if (a[i] == "1") {
              str = str + box[i] + ","
            }
          }
          if (str) {
            str = str.slice(0, -1)
          }
          return str;
        }
      }
    },
  }
</script>



<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card" style="min-height: 300px;">
        <div class="card-body">
          <h4 class="card-title pb-3"><span class="mr-2">近期成绩查询开放情况</span>
            <span><a href="/admin/scoreList"><i class="iconfont icon-more head-more" id="more"></i></a>
              <b-tooltip target="more" placement="bottom">更多</b-tooltip>
            </span>

          </h4>
          <div class="table-responsive border">
            <table class="table  light-table table-hover">
              <thead class="thead-light">
                <tr>
                  <th style="width: 4%;">序号</th>
                  <th>考试名称</th>
                  <th style="width: 12%;">开放日期</th>
                  <th style="width: 9%;">成绩记录</th>
                  <th style="width: 12%;">复核范围 </th>
                  <th style="width: 12%;">复核时间 </th>
                  <th style="width: 6%;">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">
                  <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                  <td>
                    <div class="font-blue" @click="gotoManage(obj.sid)">{{obj.ksmc}}</div>
                  </td>
                  <td> {{obj.kfrq}} </td>
                  <td>{{obj.cjjl}}</td>
                  <td>{{cjfhfw(obj.fhcjfw)}}</td>
                  <td>
                    <div>
                      <div>{{obj.fhkssj}}</div>
                      <div>{{obj.fhjssj}}</div>
                    </div>
                  </td>
                  <td class="tab-btn">

                    <a :href="'/admin/scoreManage?sid='+obj.sid" target="_blank" :title="obj.ksmc">
                      <el-button type="primary" size="mini" class="h30"><i class="iconfont icon-come-l  mr-1"></i>进入
                      </el-button>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="float-right d-flex">
            <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
              :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
              layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
            </el-pagination>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>
